import * as React from "react"
import { useState } from "react"
import { graphql, Link } from "gatsby"
import { Main } from "../../../layouts/main"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"

// Importing components
import { Seo } from "../../../components/seo"

// Importing styles
import "../styles/product-page.scss"

// Importing Bootstrap components
import { Carousel, Col, Container, Row } from "react-bootstrap"

export default function Product({ data: { product, suggestions } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Main>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(firstImage.gatsbyImageData)}
        />
      ) : undefined}
      <div className="product-page">
        <Container>
          {hasImages && (
            <Row
              className="intro"
              role="group"
              aria-label="gallery"
              aria-describedby="instructions"
            >
              <Col className="images" xs={12} sm={6}>
                {images.length > 1 ? (
                  <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                  >
                    {images.map((image, index) => (
                      <Carousel.Item>
                        <GatsbyImage
                          className="image"
                          objectFit="contain"
                          loading={index === 0 ? "eager" : "lazy"}
                          alt={
                            image.altText
                              ? image.altText
                              : `Product Image of ${title} #${index + 1}`
                          }
                          image={image.gatsbyImageData}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  images.map((image, index) => (
                    <GatsbyImage
                      className="image"
                      objectFit="contain"
                      loading={index === 0 ? "eager" : "lazy"}
                      alt={
                        image.altText
                          ? image.altText
                          : `Product Image of ${title} #${index + 1}`
                      }
                      image={image.gatsbyImageData}
                    />
                  ))
                )}
              </Col>
              <Col className="text" xs={12} sm={6}>
                <div className="yell">{price}</div>
                <h1 className="shout">{title}</h1>
                <div className="actions">
                  <div className="quantity">
                    <NumericInput
                      aria-label="Quantity"
                      onIncrement={() =>
                        setQuantity((q) => Math.min(q + 1, 20))
                      }
                      onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                      onChange={(event) =>
                        setQuantity(event.currentTarget.value)
                      }
                      value={quantity}
                      min="1"
                      max="20"
                    />
                  </div>
                  <fieldset className="variants">
                    {hasVariants &&
                      options.map(({ id, name, values }, index) => (
                        <div key={id}>
                          <select
                            aria-label="Variants"
                            onChange={(event) =>
                              handleOptionChange(index, event)
                            }
                          >
                            <option value="">{`Select ${name}`}</option>
                            {values.map((value) => (
                              <option value={value} key={`${name}-${value}`}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                  </fieldset>

                  <div className="add-to-cart">
                    <AddToCart
                      variantId={productVariant.storefrontId}
                      quantity={quantity}
                      available={available}
                    />
                  </div>
                </div>
              </Col>
              <Col className="description" xs={12}>
                <h3 className="declare">Product description:</h3>
                {/* <p className="talk">{description}</p> */}
                <div
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                ></div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </Main>
  )
}

export const query = graphql`
  query ($id: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      descriptionHtml
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(limit: 3) {
      nodes {
        ...ProductCard
      }
    }
  }
`
